.catalog {
	display: flex;
	width: 100%;
}

.catalog input {
	padding: 15px 0px 15px 33px;
	width: 100%;
	color: #c0c0c0;
	font-size: 16px;
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	background: #f7f7f7;
	border: 1px solid #cccccc;
	outline: none;
}

.catalog-container {
	width: 100%;
}

.error-msg {
	color: red;
	text-align: center;
	margin-top: 20px;
}

.catalog-img {
	margin: 20px 10px 0px 0px;
	width: 48%;
	display: inline-block;
	height: 300px;
	position: relative;
}

.catalog-img .action-cont {
	cursor: pointer;
	position: absolute;
	bottom: 20px;
	right: 10px;
	width: 90%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* background-color: #bc9144; */
}

.action-text h2 {
	font-family: "Arsenica";
	font-weight: 400;
	font-size: 24px;
	color: white;
	line-height: 113%;
	margin-bottom: 5px;
}
.action-location {
	display: flex;
}

.action-location p {
	margin-left: 5px;
	font-size: 14px;
	font-family: "Roboto", sans-serif;
	font-weight: 300;
	line-height: 130%;
	color: white;
}

.action-img {
	display: flex;
	justify-content: space-between;
	width: 30%;
}

.action-img div {
	width: 46px;
	height: 37px;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
}

.catalog-img .pictures {
	width: 100%;
	height: 100%;
}

.popup {
	width: 466px;
	height: 258px;
	border-radius: 10px;
	box-sizing: border-box;
	z-index: 2000;
	background-color: rgba(20, 20, 20, 1);
	margin: 0px auto;
	padding: 35px 43px 0px 43px;
	opacity: 1;
	transform: translate(-50% -50%) scale(0.5);
	transition: opacity 2000ms ease-in-out,
		top 2000ms ease-in-out transform 2000ms ease-in-out;
}
.popup p {
	color: white;
}

.center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.popup-buttons button {
	width: 100%;
	margin-top: 20px;
	border-radius: 10px;
}

@media screen and (max-width: 1000px) {
	.center {
		top: 30%;
	}
}

@media screen and (max-width: 934px) {
	.catalog-img {
		width: 100%;
	}
}

@media screen and (max-width: 790px) {
	.popup {
		width: 90%;
	}
}

@media screen and (max-width: 710px) {
	.action-img {
		width: 35%;
	}
}

@media screen and (max-width: 500px) {
	.catalog input {
		padding: 15px 0px 15px 15px;
	}

	.catalog-img {
		height: 250px;
	}

	.catalog-img .action-cont {
		flex-direction: column;
		width: 100%;
		right: 0px;
	}

	.action-img {
		width: 60%;
		margin-top: 20px;
	}

	.popup p {
		font-size: 15px;
	}
}

@media screen and (max-width: 450px) {
	.popup {
		padding: 35px 23px 0px 23px;
	}

	.popup-buttons button {
		padding: 19px 30px;
	}
}

@media screen and (max-width: 390px) {
	.catalog-img {
		height: 200px;
	}
	.action-img {
		width: 80%;
	}
}
