.sidebar {
	width: 25%;
	background-color: #0b0728;
	min-height: 100vh;
	padding: 50px 50px;
}

.sidebar-container {
	height: 100%;
}

.view {
	font-family: "Roboto", sans-serif;
	font-size: 16px;
	font-weight: 400;
	color: white;
	opacity: 0.5;
	line-height: 134%;
	margin-top: 20px;
	text-decoration: none;
}

.sidebar-links {
	margin-top: 70px;
}

.sidebar-logo {
	display: flex;
	width: 100%;
	margin-bottom: 40px;
}

.sidebar-logo a,
.sidebar-logo p {
	font-family: "Roboto", sans-serif;
	font-size: 18px;
	font-weight: 400;
	color: white;
	margin-left: 33px;
	text-decoration: none;
	transition: 1s all ease;
}

.sidebar-logo a:hover {
	color: #bc9144;
	transform: scale(1.2);
}

.sidebar-logo p:hover {
	color: #bc9144;
	transform: scale(1.2);
}

.sidebar-logo .active {
	font-weight: 700;
}

.logout {
	margin-top: 150px;
}

.copy-right {
	font-family: "Roboto", sans-serif;
	font-weight: 300;
	font-size: 14px;
	line-height: 131%;
	color: white;
	width: 80%;
	margin-top: 70px;
}

.big-container {
	display: flex;
	width: 100%;
}
@media screen and (max-width: 1120px) {
	.sidebar-container > img {
		width: 100%;
	}
}

@media screen and (max-width: 600px) {
	.sidebar {
		padding: 50px 20px;
	}

	.copy-right {
		width: 100%;
	}

	.sidebar-logo a,
	.sidebar-logo p {
		margin-left: 20px;
	}
}

@media screen and (max-width: 532px) {
	.sidebar-logo a,
	.sidebar-logo p {
		margin-left: 10px;
	}
}

@media screen and (max-width: 500px) {
	.sidebar-logo a,
	.sidebar-logo p {
		font-size: 15px;
	}
}

@media screen and (max-width: 430px) {
	.sidebar-logo a,
	.sidebar-logo p {
		font-size: 12px;
	}
}
