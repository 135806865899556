.properties-container {
  background-color: #0b0728;
  opacity: 0.9;
  padding: 40px 0px;
}

.properties-header {
  display: flex;
  margin-top: 100px;
  align-items: center;
  justify-content: space-between;
}

.properties-header-text {
  width: 42%;
}

.location {
  display: flex;
  align-items: center;
}

.location p {
  color: white;
  margin-left: 10px;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 140%;
}

.properties-header-text h2 {
  color: white;
  font-size: 60px;
  font-family: "Arsenica";
  font-weight: 400;
  line-height: 110%;
  margin-top: 15px;
}

.properties-header-text p {
  color: white;
  font-size: 20px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 140%;
  margin-top: 20px;
}

.properties-header-img {
  width: 45%;
}

.properties-header-img img {
  width: 100%;
}

.properties-complex {
  display: flex;
  align-items: center;
}

.properties-complex .complex-img {
  width: 50%;
  position: relative;
}

.complex-img img {
  width: 100%;
}

.complex-text {
  position: absolute;
  width: 60%;
  right: 0;
  background-color: #0b0728;
  padding: 59px 0px 59px 126px;
}

.complex-text p {
  width: 75%;
  color: white;
  font-size: 18px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 250%;
}

.appointment {
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
}

.appointment-form {
  background-color: #0b0728;
  width: 50%;
  padding: 100px 140px 100px 120px;
}

.appointment-form h2 {
  width: 100%;
}

.amenities {
  width: 50%;
  background-color: #bc9144;
  padding: 100px 140px 100px 120px;
}

.amenities h2 {
  color: white;
  font-size: 45px;
  font-weight: 400;
  font-family: "Arsenica";
  line-height: 113%;
}

.amenities-body {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  width: 100%;
  margin-top: 74px;
}

.amenities-body div {
  width: 20%;
}

.amenities-body .amenities-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.amenities-body div p {
  color: white;
  margin-top: 26px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

.amenities-body .power {
  margin-top: 5px;
}

.explore-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 100px;
}

.explore-header h2 {
  font-family: "Arsenica";
  font-size: 45px;
  font-weight: 400;
  color: #1a1a1a;
  line-height: 113%;
}

@media screen and (max-width: 1200px) {
  .amenities {
    padding: 100px 40px 100px 20px;
  }
}

@media screen and (max-width: 1000px) {
  .appointment-form {
    padding: 100px 40px 100px 20px;
  }

  .properties-complex {
    flex-direction: column;
    padding: 0px 50px;
  }

  .properties-complex .complex-img {
    width: 100%;
  }

  .properties-complex .complex-text {
    position: unset;
    width: 100%;
    box-sizing: border-box;
    padding: 59px 20px;
  }

  .complex-text p {
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .appointment-form {
    padding: 100px 40px 100px 0px;
  }
  .properties-header {
    flex-direction: column-reverse;
  }

  .properties-header-img,
  .properties-header-text {
    width: 100%;
  }

  .appointment {
    flex-direction: column;
  }

  .appointment-form {
    width: 100%;
  }

  .amenities {
    width: 100%;
    padding: 0px 20px;
  }
}

@media screen and (max-width: 500px) {
  .amenities {
    padding: 0px 0px;
  }

  .amenities-body {
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }

  .amenities h2 {
    text-align: center;
  }

  .amenities-body div {
    width: 100%;
    margin: 15px 0px;
  }

  .properties-complex {
    flex-direction: column;
    padding: 0px 20px;
  }
}
