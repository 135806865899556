.slider {
	position: relative;
	background-color: linear-gradient(
		180deg,
		rgba(11, 7, 40, 0.29) 8.52%,
		#0b0728 102.99%
	);
}
.slide-container {
	width: 100%;
	height: 786px;
}

.slide-container img {
	width: 100%;
	height: 100%;
	position: relative;
}

.slide-overlay {
	position: absolute;
	left: 0px;
	width: 100%;
	background: linear-gradient(
		180deg,
		rgba(11, 7, 40, 0.29) 8.52%,
		#0b0728 102.99%
	);
	height: 100%;
	z-index: 1;
}

.slider .left,
.slider .right {
	position: absolute;
	cursor: pointer;
	z-index: 111111;
}

.left {
	left: 10%;
	top: 40%;
}

.right {
	right: 10%;
	top: 40%;
}

.content {
	position: absolute;
	bottom: 100px;
	left: 10%;
	box-sizing: border-box;
	overflow: hidden;
	z-index: 1;
}

.content h2 {
	font-family: "Arsenica";
	font-size: 60px;
	font-weight: 400;
	color: white;
}

.content p {
	color: white;
	font-size: 18px;
	font-family: "Roboto", sans-serif;
	font-weight: 300;
	width: 55%;
	padding: 20px 0px;
}

@media screen and (max-width: 1000px) {
	.content p {
		width: 50%;
	}
}

@media screen and (max-width: 700px) {
	.content p {
		width: 80%;
	}
}

@media screen and (max-width: 500px) {
	.content {
		bottom: 20px;
		left: 20px;
	}
}
