.contact-header {
	background-color: #0b0728;
	/* opacity: 0.9; */
	padding: 40px 0px;
}

.contact-container {
	display: flex;
	margin-top: 100px;
	align-items: center;
}

.contact-header-text h2 {
	color: white;
	font-family: "Arsenica";
	font-size: 60px;
	font-weight: 400;
	line-height: 113%;
}

.contact-header-text p {
	font-weight: 300;
	font-size: 20px;
	line-height: 170%;
	color: white;
	font-family: "Roboto", sans-serif;
	width: 55%;
	margin-top: 50px;
}

.contact-header-text .mail {
	margin-top: 35px;
}

.contact-header-text .phone {
	margin-top: 20px;
}

.social-icons img {
	margin-left: 50px;
	margin-top: 60px;
	cursor: pointer;
	transition: 1s all ease-in-out;
}

.social-icons img:hover {
	transform: scale(0.8);
}

.social-icons img:first-child {
	margin-left: 0px;
}

.contact-header-img {
	width: 50%;
	position: relative;
}

.rectangle5 {
	position: absolute;
	top: 50px;
	width: 80%;
	left: 50px;
}

.contact-header-img div img {
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 1200px) {
	.rectangle5 {
		display: none;
	}
}

@media screen and (max-width: 1000px) {
	.contact-container {
		flex-direction: column-reverse;
	}

	.contact-header-img {
		width: 100%;
	}
}

@media screen and (max-width: 340px) {
	.contact-header-text h2 {
		font-size: 40px;
	}
}
