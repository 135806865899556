.landing {
	overflow: hidden;
}

.landing-header {
	background-image: url(../../assets/Rectangle\ 30.png);
	background-size: cover;
	position: relative;
	padding: 40px 0px;
}

.landing-header .overlay {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	height: 100%;
}

.landing-cont {
	position: relative;
	z-index: 99999;
}

.welcome-container {
	display: flex;
	margin: 80px 0px;
	/* align-items: center; */
	width: 100%;
	justify-content: space-between;
}

.welcome-text {
	width: 53%;
}

.welcome-text h2 {
	width: 100%;
	font-size: 60px;
	font-weight: 400;
	color: white;
	margin: 0px;
	font-family: "Arsenica";
	text-transform: capitalize;
}

.welcome-text p {
	color: white;
	font-family: "Roboto", sans-serif;
	font-weight: 300;
	font-size: 15px;
	line-height: 145%;
	margin: 0px;
	margin-top: 30px;
	width: 75%;
}

.welcome-text button {
	margin-top: 42px;
}

.welcome-text button img {
	margin-left: 20px;
}

.welcome-img {
	width: 42%;
	position: relative;
}

.rectangle {
	width: 90%;
	top: 20px;
	left: 20px;
	height: 90%;
	position: absolute;
}

.welcome-img div img {
	width: 100%;
}

.offer {
	background-color: #0b0728;
	width: 100%;
	padding: 150px 0px;
}

.offer-body {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}

.offer-text {
	width: 30%;
}

.offer-text p {
	font-size: 20px;
	font-weight: 300;
	font-family: "Roboto", sans-serif;
	line-height: 145%;
	color: white;
	margin-bottom: 5px;
}

.offer-text h2 {
	font-size: 50px;
	font-family: "Arsenica";
	color: white;
	font-weight: 400;
}

.offer-conts {
	display: flex;
	width: 70%;
}

.offer-single {
	margin-left: 40px;
	width: 20%;
}

.single-offer {
	width: 100%;
	background-color: #bc9144;
	height: 207px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.offer-single h4 {
	font-size: 20px;
	font-weight: 400;
	font-family: "Roboto", sans-serif;
	color: white;
	margin-top: 30px;
	line-height: 30px;
}

.vertical-slants {
	position: absolute;
	top: -6.7rem;
	left: 0;
	z-index: 1;
}

.square-slants {
	position: absolute;
	bottom: -4rem;
	width: 100%;
	display: flex;
	justify-content: center;
	z-index: 1;
}

.slideshow-container {
	width: 100%;
}

.map {
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 150px 0px;
	/* align-items: center; */
}

.map iframe {
	width: 50%;
	height: 500px;
}

.map-details {
	width: 48%;
	background: rgba(188, 145, 68, 0.14);
}

.map-details p {
	width: 75%;
	color: #1e1e1e;
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-size: 16px;
	line-height: 250%;
	margin: 50px auto 0px;
}

.map-details img {
	margin: 20px 70px;
}

.consultation {
	background-color: #0b0728;
	padding: 150px 0px 250px;
	position: relative;
}

.consultation-body {
	width: 65%;
	margin: 0px auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.consultation-img {
	width: 50%;
	position: relative;
}

.consultation-img .log {
	position: absolute;
	top: 130px;
	left: -150px;
	z-index: 1;
}

.consultation-img .chat {
	position: absolute;
	top: 430px;
	right: -40px;
	z-index: 1;
}

.rectangle2 {
	position: absolute;
	left: -30px;
	bottom: -30px;
}

.consultation-form {
	margin-left: 100px;
}

.whatsapp {
	position: fixed;
	bottom: 50px;
	right: 50px;
	cursor: pointer;
	z-index: 99999;
}

.whatsapp img {
	width: 100%;
}

@media screen and (max-width: 1220px) {
	.consultation-body {
		width: 80%;
	}

	.rectangle,
	.rectangle2,
	.vertical-slants,
	.square-slants,
	.log,
	.chat {
		display: none;
	}
}

@media screen and (max-width: 990px) {
	.consultation-body {
		width: 90%;
	}
}

@media screen and (max-width: 1000px) {
	.offer-body {
		flex-direction: column;
	}

	.offer-text {
		width: 100%;
		text-align: center;
		margin-bottom: 50px;
	}

	.offer-conts {
		width: 100%;
	}

	.offer-conts .offer-single:first-child {
		margin-left: 13px;
	}
}

@media screen and (max-width: 900px) {
	.welcome-container {
		flex-direction: column-reverse;
	}

	.welcome-img {
		width: 100%;
	}

	.welcome-text {
		width: 100%;
	}

	.welcome-text h2 {
		font-size: 35px;
	}

	.consultation-body {
		flex-direction: column;
	}

	.consultation-img {
		width: 100%;
	}

	.consultation-img img {
		width: 100%;
	}

	.consultation-form {
		margin-left: 0px;
		margin-top: 20px;
	}
}

@media screen and (max-width: 700px) {
	.map {
		flex-direction: column-reverse;
	}

	.map iframe {
		width: 100%;
	}

	.map-details {
		width: 100%;
	}

	.offer-conts {
		flex-direction: column;
	}

	.offer-conts .offer-single {
		width: 50%;
		margin: 0px auto !important;
	}

	.offer-single h4 {
		margin: 15px 0px;
		text-align: center;
	}
}

@media screen and (max-width: 480px) {
	.offer-conts .offer-single {
		width: 70%;
	}

	.welcome-text p {
		width: 100%;
	}
}
