.footer-container {
	width: 100%;
	padding: 150px 0px 0px;
}

.footer-newsletter {
	width: 41%;
	margin: auto;
	text-align: center;
}

.footer-newsletter h2 {
	color: #0b0728;
	font-size: 40px;
	line-height: 113%;
	font-family: "Arsenica";
	font-weight: 400;
}

.footer-newsletter p {
	color: #7d7d7d;
	font-size: 20px;
	line-height: 113%;
	font-weight: 400;
	font-family: "Roboto", sans-serif;
	margin-top: 10px;
}

.newsletter-input {
	width: 100%;
	background: #ffffff;
	border: 1px solid #dfdfdf;
	box-shadow: 0px 95px 103px -21px rgba(0, 0, 0, 0.1);
	margin-top: 40px;
	display: flex;
	justify-content: space-between;
	padding: 20px;
	margin-bottom: 150px;
}

.newsletter-input input {
	width: 70%;
	border: none;
	outline: none;
}

.newsletter-input button {
	box-shadow: 0px 30px 39px -8px rgba(255, 153, 0, 0.2);
}

.footer-cont {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.footer-logo {
	width: 20%;
}

.footer-logo img {
	width: 100%;
}

.footer-cont p,
.footer-cont a {
	font-family: "Roboto", sans-serif;
	font-weight: 300;
	font-size: 12px;
	line-height: 166%;
	color: #1e1e1e;
	display: block;
	text-decoration: none;
}

.quick-links p,
.help-links p {
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 10px;
	color: #1e1e1e;
	font-family: "Roboto", sans-serif;
}

.footer-address {
	width: 22%;
}

.footer-address .socials {
	width: 80%;
	margin-top: 20px;
	justify-content: space-between;
	display: flex;
}

.footer-address .mail {
	/* margin-top: 40px; */
	margin-bottom: 20px;
}

.footer-copyright {
	background-color: #bc9144;
	color: white;
	padding: 30px 0px;
	margin-top: 100px;
}

.footer-copyright p {
	font-size: 14px;
	opacity: 0.75;
	font-weight: 400;
	font-family: "Roboto", sans-serif;
	line-height: 23px;
}

.footer-copyright p span {
	opacity: 0.75;
}

.footer-copyright p a {
	text-decoration: none;
	color: rgba(255, 255, 255, 0.75);
}

.footer-copyright p a:hover {
	text-decoration: none;
	color: rgba(255, 255, 255, 1);
}

@media screen and (max-width: 900px) {
	.footer-newsletter {
		width: 70%;
	}
}

@media screen and (max-width: 740px) {
	.footer-cont {
		flex-direction: column;
	}

	.footer-logo {
		margin: 0px auto;
		width: 50%;
	}

	.quick-links,
	.help-links,
	.footer-address {
		width: 100%;
		text-align: center;
	}
}

@media screen and (max-width: 500px) {
	.footer-newsletter {
		width: 90%;
	}

	.newsletter-input {
		box-sizing: border-box;
	}
}

@media screen and (max-width: 360px) {
	.newsletter-input {
		flex-direction: column;
	}

	.newsletter-input input {
		width: 90%;
		margin-bottom: 10px;
		padding: 15px;
		/* border: 1px solid #0b0728; */
	}
}
