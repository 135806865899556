@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap");
html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	margin: 0px;
	padding: 0px;
}

@font-face {
	font-family: Arsenica;
	src: url(./fonts/ArsenicaTrial-Regular.ttf);
}

@font-face {
	font-family: ArsenicaBold;
	src: url(./fonts/ArsenicaTrial-Demibold.ttf);
}
